<template>
  <div class="container">
    <AccountNotFoundModal :showing-modal="openPopupModal"
                           @click-confirm="confirmModalAccountNotFound"
                           @show-modal="closeModal"/>
    <Content>
      <div class="login-content">
        <div class="text-login">
          <button class="back-button" @click="closePopUp">
            <img :src="arrowLeft" alt="arrow-left"/>
          </button>
          <span class="text-login">LOG IN</span>
        </div>
        <form class="form">
          <BaseInput
            class="form-group"
            id="login_email"
            :placeholder="configEnv.onboarding.emailPlaceHolder"
            v-model="formData.mail"
            :hasError="$v.formData.mail.$error"
            :show-done="($v.formData.mail.required && $v.formData.mail.mustBeCool)"
            ref="baseInput"
          >
            <template slot="error-message">
            </template>
          </BaseInput>
          <BaseInput
            class="form-group"
            placeholder="Password"
            id="login_password"
            v-model="formData.password"
            type="password"
            :isPassword="true"
            :hasError="$v.formData.password.$error"
            :submit-with-help-enter="true"
            @submit="login"
          >
            <template slot="error-message">
            </template>
          </BaseInput>
          <div v-if="showTextIncorrectText" class="login-error-message">
            <img :src="errorIcon" alt="error-icon" class="left"/>
            <span>Incorrect Password</span>
          </div>
          <div class="block-button">
            <span
              @click="forgotPass"
              class="forgot-pass">Forgot password?</span>
          </div>
        </form>
      </div>
      <ActionButton
        v-if="!$v.formData.password.$invalid && !$v.formData.mail.$invalid"
        text="LOGIN"
        id="log_in"
        @click-button="login"
      />
    </Content>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import configEnv from '@configEnv';
import checkRole from '@helpers/adminFunction';
import differentsServices from '@helpers/differentsServices';
import errorIcon from '@assets/error-icon.svg';
import AccountNotFoundModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/AccountNotFound/AccountNotFoundModal.vue';
import arrowLeft from '@assets/arrow_left.svg';
import helpFunction from '@helpers/helpFunction';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import BaseInput from '@innerworks_ds/innerworks-storybook-main/src/stories/CustomInputs/BaseInput/BaseInput.vue';
import userBehave from '@constants/userBehave';

const { required, minLength } = require('vuelidate/lib/validators');

export default {
  components: {
    ActionButton,
    AccountNotFoundModal,
    BaseInput,
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      mail: {
        required,
        mustBeCool: helpFunction.mustBeCool,
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  data: () => ({
    arrowLeft,
    errorIcon,
    configEnv,
    showInfoModalAboutGeolocation: false,
    showTextIncorrectText: false,
    formData: {
      mail: null,
      password: null,
    },
    updatedTimeOut: null,
    countUpdate: 1,
    diaCode: '',
    loading: false,
    openPopupModal: false,
    done: true,
    googleCaptcha: false,
  }),
  props: {
    resultPage: {
      type: Boolean,
      defaultValue: false,
    },
  },
  beforeMount() {
    this.recaptcha();
  },
  computed: {
    ...mapGetters({
      getRedirectAuth: 'auth/getRedirectAuth',
      getProfile: 'auth/getProfile',
      logIn: 'showComponent/logIn',
    }),
    getClassByLengthCountryCode() {
      return `code-length-${this.diaCode.length}`;
    },
  },
  beforeCreate() {
    this.$store.dispatch('showComponent/showHeaderLogo');
  },
  methods: {
    confirmModalAccountNotFound(value) {
      this.$api.userBehaveSite.saveSiteEvent(userBehave.redirectToRegisterPage);
      this.$router.push({
        name: 'registration',
      });
      this.openPopupModal = value;
    },
    async recaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      this.$api.auth.checkBotGoogleCaptcha(token).then((result) => {
        this.googleCaptcha = result;
      });
    },
    closeModal(value) {
      this.openPopupModal = value;
    },
    forgotPass() {
      this.$router.push('/reset-password');
    },
    updatePhoneData() {
      clearTimeout(this.updatedTimeOut);
      this.updatedTimeOut = setTimeout(() => {
        if (this.formData.phone && this.diaCode) {
          this.formData.phone = this.formData.phone.replace(`+${this.diaCode}`, '');
        }
      }, 50);
    },
    prepareDataForRequest() {
      let uniqueId = null;
      if (localStorage.getItem('uniqueId') !== null) {
        uniqueId = localStorage.getItem('uniqueId');
      }

      return {
        password: this.formData.password,
        mail: this.formData.mail,
        uniqueId,
        questionId: process.env.QUESTIONNAIRE_ID,
        fingerPrintData: differentsServices.getClientData(),
      };
    },
    closePopUp() {
      this.$emit('close-login-page', true);
    },
    countryChanged(data) {
      this.diaCode = data.dialCode;
    },
    savePhoto(userWithoutPhoto, email, id) {
      if (userWithoutPhoto) {
        differentsServices.avatarPhotoService(email, id);
      }
    },
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.googleCaptcha) {
        this.loading = true;
        const data = await this.prepareDataForRequest();
        try {
          await this.$store.dispatch('auth/loginRequest', data);
          const {
            completedQuestionnaires, userWithoutPhoto, email, id,
          } = this.getProfile;

          this.savePhoto(userWithoutPhoto, email, id);
          this.loading = false;

          if (checkRole.isAdmin()) {
            this.$router.push({
              name: 'adminMenu',
            });
          } else if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
            this.$router.push('/questionnaire');
            this.$store.dispatch('showComponent/gotToStepInitial');
          } else if (!checkRole.isAdmin()) {
            this.$router.push('/result');
          } else {
            this.$router.replace(this.getRedirectAuth);
          }
        } catch (error) {
          this.loading = false;
          if (error.response.status === 404) {
            this.openPopupModal = true;
          } else {
            this.showTextIncorrectText = true;
          }
        }
      }
    },
    redirectToMainPage() {
      this.$router.push({ name: 'main' });
    },
    focusInput() {
      this.$refs.baseInput.focusInput();
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('showComponent/gotToStepInitial');
    },
  },
};

</script>
<style lang="scss" scoped>
  .container {
    height: auto;
    overflow: hidden;
  }
  .back-button {
    position: absolute;
    left: 45px;
  }
</style>

<style lang="scss">
  button:focus {
    border: 2px solid #66afe9;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
  }
  .login-error-message {
    text-align: left;
    font-family: $newDefaultFont;
    font-weight: 300;
    font-size: 14px;
    align-items: center;
    color: #FF5151;

    margin-bottom: 40px;
    span {
      padding-left: 10px;
    }
  }
  .change-details-link {
    float: right;
  }
  .button_theme-default {
    background-color: #BC88E4;
  }
  .forgot-password {
    text-align: inherit !important;
  }
  .forgot-pass {
    cursor: pointer;
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #7811C9;
  }
  .text-login {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 45px;
    color: rgba(50, 9, 89, 0.8);
  }
  .image-done {
    position: absolute;
  }
  .login-content {
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 100px;
  }
  .block-button {
    display: flex;
    justify-content: space-around;
    margin-top: 45px;
    align-items: center
  }
  .button-login {
    background-color: #7811C9;
    padding: 12px 61px 12px 61px;
    border-radius: 5px;
    width: 100%;
    bottom: 0;
    height: 80px;
    position: absolute;
    max-width: 480px;
    left: 0;
  }
  .logo-innerworks {
    padding-left: 25px;
    padding-top: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  .logo-text {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $txtColorNewDesignMain;
    padding-left: 9px;
    padding-top: 5px;
  }
</style>
