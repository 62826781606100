<template>
  <div>
    <RegistrationPage
      v-if="showRegisterForm"
    />
    <ExpandAll
      v-if="showResult"
    />
    <SelfAnswer
    v-if="showResult"
    />
    <BaseShowImg
      :text="textButtonProcess"
      :src="srcProcessing"
    />
    <BaseShowImg
    v-if="isAuth"
      :text="textButtonResult"
      :src="resulGraphics"
    />
    <Content>
      <FrequentlyAskedQuestions
        v-if="showFreQuestions"
      />
    </Content>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FrequentlyAskedQuestions from '@modules/FrequentlyAskedQuestions';
import ExpandAll from '@modules/ExpandAll';
import SelfAnswer from '@modules/SelfAnswer';
import RegistrationPage from '@components/Onboarding/RegistrationPage.vue';
import resulGraphics from '../assets/result_graphics.png';
import user1 from '../assets/user_1_journey.png';
import user2 from '../assets/user_2_journey.png';

export default {
  name: 'BlockQuestions',
  data: () => ({
    user1,
    user2,
    resulGraphics,
    questions: null,
    loading: false,
    srcProcessing: null,
    textButtonProcess: 'How does it work ?',
    textButtonResult: 'What do the result show ?',
  }),
  components: {
    FrequentlyAskedQuestions,
    RegistrationPage,
    ExpandAll,
    SelfAnswer,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      showFreQuestions: 'showComponent/showFreQuestions',
      showRegisterForm: 'showComponent/showRegisterForm',
      showResult: 'showComponent/showResult',
      closeToFriend: 'showComponent/closeToFriend',

    }),
    isAuth() {
      return this.getProfile.token;
    },
  },
  created() {
    const url = window.location;
    const checkHref = url.href.search('invitation');
    if (checkHref > -1) {
      this.$store.commit('showComponent/setShowHeader4', true);
      this.$store.commit('showComponent/setShowHeader1', false);
      this.$store.commit('showComponent/setCloseToFriend', true);
      this.srcProcessing = this.user2;
    } else {
      this.srcProcessing = this.user1;
    }
  },
};
</script>

<style lang="scss">
</style>
